/**
 * @module js/oPINAuthenticatorService
 */
import app from 'app';
import _appCtxSvc from 'js/appCtxService';
import MomUtilsService from 'js/MomUtilsService';
import cfgSvc from 'js/configurationService';
import axios from 'axios';
import opCenterIPService from 'js/oPINIPService';
//import OPINSocketEventService from 'js/OpcenterEventSubscriptionService'
import _localeService from 'js/localeService';
import oPINCtxMindsphereService from 'js/oPINCtxMindsphereService';
import analyticsSvc from 'js/analyticsService';
import popupSvc from 'js/popupService';
import _walkmeService from 'js/walkmeService';
import awStateService from 'js/awStateService';

//MPCODE: Init MP configuration
import mpInit from 'js/mpInitService';
//MPCODE: Init MP configuration

let exportsObj = {};
let cfgLoaded = false,
    inLogout = false,
    inLogin = true;
let lastUserName = '';
let walkmeLoaded = false;
// To show Label by default
MomUtilsService.toggleCommandLabels();
_localeService.getTextPromise( 'authModuleMessages' ).then(function ( message ) {
    _appCtxSvc.registerCtx( 'momProductSettings', {
        name: 'Opcenter Intelligence',
        version: message['ocinVersion'],
        legalEntity: 'Copyright © 2024 Siemens',
        logoImagePath: 'assets/images/appOcIn32.svg'
    } );
} );
//fake promise
var sw = Promise.resolve();
//Mindsphere Service Worker init
if (window.location.hostname.indexOf('mindsphere.io') > 0) {
    if ("serviceWorker" in navigator) {
        sw = new Promise(async (res, rej) => {
            try {
                var registration = await navigator.serviceWorker.register("ServiceWorker.js");
                if (registration.active) {
                    console.debug("Service Worker Status Active");
                    res();
                } else {
                    const swStatus = registration.installing || registration.waiting;
                    swStatus.onstatechange = function () {
                        console.debug("Service Worker Status Installing/Waiting");
                        if (swStatus.state === 'activated') {
                            console.debug("Service Worker Status Active");
                            swStatus.onstatechange = null;
                            window.location.reload();
                        }
                    };
                }
            } catch (error) {
                console.error('ServiceWorker Error' + error);
                res();
            }
        });
    }
}
awStateService.instance.transitionHooks.onSuccess({to: '**'},transition =>{
    //console.log("TRANSITION ",transition); 
    _appCtxSvc.updateCtx("oPINLocationContext", {
        "ActiveWorkspace:Location": awStateService.instance.current.parent || "",
        "ActiveWorkspace:SubLocation":awStateService.instance.current.name || ""
    });
})
export let getAuthenticator = function () {
    var exports = {};
    //Callback for logout notification
    function logoutClbk() {
        console.debug("Callback called: logoutClbk")
        if (!inLogout && !inLogin) { //not my logout
            afterLogout();
        }
    };
    //Callback for token refresh notification
    function newTokenClbk(evData) {
        if (evData && evData.data && !inLogin) { //new Token
            if (lastUserName.length === 0 || lastUserName === evData.data.accessToken.payload.username) {
                onAuthSolved(evData.data);
            } else {
                logoutClbk(); //new user ... like logout and login
            }
        }
    }
    exports.location_reload = function( par ) {
        location.reload( par ); //reloadCurrentPage
    };
    /**
     * Callback for new Language notification
     * @param {string} lang 
     */
    function newLanguageClbk( lang ) {
        _localeService.setLocale( lang );
        exports.location_reload( true );
    }
    /**
     * Chnage Telemetry flag
     * @param {boolean} flag 
     */
    function newTelemetryClbk(flag) {
        exports.changeTelemetry({
            value: {
                dbValue: flag
            }
        });
    }
    var getEnabledFeatures = function (token) {
        let list = _appCtxSvc.getCtx('FeaturesList');
        var ctxValue = _.map(list, 'FeatureId');
        var ctxValueEnum = _.chain(list)
            .keyBy('Name')
            .mapValues('FeatureId')
            .value();
        _appCtxSvc.updateCtx('miStudioFeatures', ctxValue);
        _appCtxSvc.updateCtx('miStudioFeaturesEnum', ctxValueEnum);
        _appCtxSvc.updateCtx('FeaturesListNames', Object.keys(ctxValueEnum));
    };

    function refreshMindsphereSessionCallBack() {
        console.debug('refreshMindsphereSession');

        axios.get('https://' + window.location.hostname + "/mp/app-info.json").then(function (response) {
            console.debug('refreshMindsphereSession ok');
        }, error => console.log("refreshMindsphereSession failed", error));
    }
    var refreshMindsphereSession = function () {
        setInterval(refreshMindsphereSessionCallBack, 60000);
    };
    /**
     * perform initialization after an authentication
     * Called every token renew
     * Contains MP specific code
     */
    async function onAuthSolved(token, deferObj) {
        //token validation, current user init and check for mindsphere
        if (token !== null && token !== undefined) {
            opCenterIPService.getTokenMode().then(mode => {
                if (typeof mode !== "string") {
                    _appCtxSvc.updateCtx('cognitoTokenMode', "Cognito");
                } else {
                    _appCtxSvc.updateCtx('cognitoTokenMode', mode);
                }
            }, error => console.log("getTokenMode failed", error));
            _appCtxSvc.updateCtx('cognitoToken', token);
            const swacMode = _appCtxSvc.getCtx("swacModeStart");
            //Enable WalkMe Service
            if (!walkmeLoaded && !swacMode) {
                _walkmeService.loadWalkMe();
                walkmeLoaded = true;
            }
            _appCtxSvc.updateCtx('momDisableGoToHomePage', true);
            if (token.accessToken.payload['cognito:groups'] !== undefined && token.accessToken.payload['cognito:groups'].length === 1 && token.accessToken.payload['cognito:groups'][0] === 'RuntimeRestrictedAccess') {
                _appCtxSvc.updateCtx('restrictedMode', true);
            } else {
                _appCtxSvc.updateCtx('restrictedMode', false);
            }
            _appCtxSvc.updateCtx('showAutoSaveToggle', false); //hide AutosaveTablesS toggle button
            var prjC = _appCtxSvc.getCtx('oPINAuthenticationConfig');
            lastUserName = token.accessToken.payload.username;
            var currentLocale = _localeService.getLocale();
            var userLang = await opCenterIPService.getLanguage();
            _appCtxSvc.updateCtx('Language', userLang);
            var msUser = "custom:mindsphereUser";
            if (token && token.idToken && token.idToken.payload && (msUser in token.idToken.payload) && (token.idToken.payload[msUser].toLowerCase() === "true")) {
                _appCtxSvc.updateCtx('mdAuthMode', true);
            }
            _appCtxSvc.updateCtx('user', {
                'fullName': token.idToken.payload.email,
                'roles': token.accessToken.payload["cognito:groups"],
                'typeIconURL': 'assets/image/cmduser16.svg',
                'hasThumbnail': true,
                'thumbnailURL': 'assets/image/cmduser16.svg'
            });
            if (token && token.idToken) {
                axios.defaults.headers.common['Authorization'] = token.idToken.jwtToken;
            } else {
                axios.defaults.headers.common['Authorization'] = '';
            }

            //MPCODE: Init MP configuration
            await mpInit.initMPCtx(token, opCenterIPService.getSwacIPComponent())
            //MPCODE: Init MP configuration

            if (userLang && currentLocale !== userLang) {
                _localeService.setLocale(userLang);
            }
            _appCtxSvc.updateCtx('momEnableAppLogo', true);
            //Features Management
            var features = _appCtxSvc.getCtx('miStudioFeatures');
            if (!features || features === []) {
                getEnabledFeatures(token);
            }
            if (window.location.hostname.indexOf('mindsphere.io') > 0) {
                refreshMindsphereSession();
            }
            //Notifications management
            var notifications = _appCtxSvc.getCtx('opinNotifications');
            if (notifications === undefined || (notifications.data && notifications.data.totalResults == 0)) {
                var url = prjC.apiMI + 'TenantService/api/notifications';
                var theUser = _appCtxSvc.getCtx("cognitoToken");
                if (!swacMode && (!theUser || !theUser.accessToken || !theUser.accessToken.payload['cognito:groups'] ||
                    !(theUser.accessToken.payload['cognito:groups'].length === 1 &&
                        theUser.accessToken.payload['cognito:groups'][0] === 'RuntimeRestrictedAccess')
                )) {
                    axios.get(url).then(function (result) {
                        if (result && result.data && Array.isArray(result.data)) {
                            var opinNotifications = { data: { Search: result.data, totalResults: result.data.length, activeNotifications: result.data.length } };
                            opCenterIPService.getNotifications().then(userNotifications => {
                                if(opinNotifications.data) {
                                    var activeNotifications = 0;

                                    opinNotifications.data.Search.forEach((item, index) => {
                                        item.notificationRead = userNotifications.includes(item.id);	

                                        if(!item.notificationRead) {
                                            activeNotifications++;
                                        }
                                    });

                                    opinNotifications.data.activeNotifications = activeNotifications;                                                            		
                                }

                                _appCtxSvc.updateCtx('opinNotifications', opinNotifications); 

                                //Remove from localstorage reference to old notifications
                                var cleanNotifications = userNotifications.filter(n=> { return opinNotifications.data.Search.map( m=> {return m.id; }).includes(n);});
                                opCenterIPService.setNotifications(cleanNotifications);
                            });
                            _appCtxSvc.updateCtx('opinNotifications', result);
                        } else {
                            _appCtxSvc.updateCtx('opinNotifications', {
                                data: {
                                    Search: [],
                                    totalResults: 0
                                }
                            });
                        }
                    }, error => console.log("request: " + url + " failed", error));
                }
            }
        } else {
            _appCtxSvc.unRegisterCtx('cognitoToken');
        }
        //application context service initialization
        //can be called multiple time and always succeed
        oPINCtxMindsphereService.buildAPContext().then(function () {
            if (deferObj) { //solve caller optional promise
                deferObj.resolve();
            }
        }, error => console.log("buildAPContext failed", error));
    }

    /**
     * Initialize the telemetry functionality
     * @param {boolean} useTelemetryInternalServer 
     * @returns 
     */
    function initStats( useTelemetryInternalServer ) {
        /**
         * useInternalServer
         * true: send Analytics data to Analytics internal server
         * false: send data to Analytics cloud-based server (AWS)
         */
        opCenterIPService.getDisclosure().then( function( disc ) {
            if ( disc === undefined || disc === null || !disc ) {
                let options = {
                    declView: 'mpDisclosure',
                    locals: {
                        caption: 'Product Excellence Program'
                    },
                    options: {
                        preset: 'modal',
                        clickOutsideToClose: false,
                        hasCloseButton: false,
                        width: 500
                    }
                };
                popupSvc.show( options );
                opCenterIPService.setDisclosure( true );
            }
        } );

        opCenterIPService.getTelemetry().then(function (tel) {
            if (tel === undefined || tel === null) {
                tel = false;
            } else {
                tel = JSON.parse( tel );
            }
            _appCtxSvc.updateCtx('enableTrace', tel);
        }, error => console.log('getTelemetry failed', error));

        opCenterIPService.getTelemetryDPE().then( function( tel ) {
            if ( tel === undefined || tel === null ) {
                tel = true;
            } else {
                tel = JSON.parse(tel);
            }
            _appCtxSvc.updateCtx('enableTraceDPE', tel);
        }, error => console.log('getTelemetryDPE failed', error) )
            .then( function() {
                var useInternalServer = true;
                var telPEP = _appCtxSvc.getCtx( 'enableTrace' );
                var telDPE = _appCtxSvc.getCtx( 'enableTraceDPE' );
                if ( useTelemetryInternalServer !== undefined ) {
                    useInternalServer = JSON.parse( useTelemetryInternalServer );
                }
                //Set the TenantKey inside the vendor_id of analytics service
                var token = _appCtxSvc.getCtx( 'cognitoToken' );
                if ( token && token.idToken ) {
                    var preInitObj = {
                        vendor_id: token.idToken.payload['custom:tenantKey'],
                        sanProductKey: 'opcenterintelligence',
                        isOptionalDataEnabled: telPEP,
                        isNecessaryDataEnabled: telDPE
                    };
                    analyticsSvc.setPreInitData( preInitObj );
                }
                //Enable the analytics
                analyticsSvc.authenticationSuccessful();
                if ( telPEP && telDPE ) {
                    analyticsSvc.enable( useInternalServer )?.then( function() {
                        analyticsSvc.logProductInfo( 'Participating', 'Opt-In' ); //Participating mean user are interested in analytics they haven't disable the analytics. if they disable it will set false.
                    } );
                } else {
                    analyticsSvc.disable( useInternalServer )?.then( function() {
                        analyticsSvc.logProductInfo( 'Participating', 'No' ); //Participating mean user are interested in analytics they haven't disable the analytics. if they disable it will set false.
                    } );
                }
            }, error => console.log( 'getTelemetryDPE 2 failed', error ) );
        return true;
    }
    /**
     * 
     * @returns 
     */
    function getFederationInUrl() {
        let fedInfoPos = window.location.href.indexOf('umcFederation');
        if (fedInfoPos !== -1) {
            let posiz = [window.location.href.indexOf('&', fedInfoPos), window.location.href.indexOf('#', fedInfoPos)].reduce((x, v) => {
                if (v > 0) {
                    if (x > 0) {
                        x = Math.min(x, v)
                    } else {
                        x = v;
                    }
                }
                return x;
            }, -1);
            if (posiz > 0) {
                return window.location.href.substring(fedInfoPos + 'umcFederation='.length, posiz)
            } else {
                return window.location.href.substring(fedInfoPos + 'umcFederation='.length)
            }
        }
        return '';
    }
    exports.checkIfSessionAuthenticated = function () {
        return new Promise((resolve, reject) => {
            sw.then(function () {
                let cfgPromise;
                _appCtxSvc.registerCtx('basePath', _appCtxSvc.getCtx('momBaseUrl'));
                _appCtxSvc.registerCtx('basePathImages', _appCtxSvc.getCtx('momBaseUrl') + '/image/');
                var cfg = _appCtxSvc.getCtx('oPINAuthenticationConfig');
                cfgPromise = cfg ? Promise.resolve(cfg) : cfgSvc.getCfg('oPINAuth-config');

                let cfgNavigationPromise;
                var navigationCfg = _appCtxSvc.getCtx('opInNavigationMenuConfig');
                cfgNavigationPromise = navigationCfg ? Promise.resolve(navigationCfg) : cfgSvc.getCfg('opInNavigationMenu-config');

                let fedName = getFederationInUrl();
                /*
                let vv = _appCtxSvc.getCtx('locationContext')
                if (vv) {
                    _appCtxSvc.updateCtx('oPINLocationContext', vv)
                }
                eventBus.subscribe('appCtx.*', function (ctxEvt) {
                    if (ctxEvt.name === 'locationContext'){// && ctxEvt.value) {
                        //if (ctxEvt.value['ActiveWorkspace:Location'] && ctxEvt.value['ActiveWorkspace:SubLocation']) {
                            _appCtxSvc.updateCtx('oPINLocationContext', ctxEvt.value)
                        //}
                    }
                });*/
                cfgPromise.then( function( conf ) {
                    cfgLoaded = true;
                    _appCtxSvc.updateCtx( 'oPINAuthenticationConfig', conf);
                    //get identiyProvider  url from conf
                    let authUrl = conf.identityProviderUrlSWAC;
                    var host = window.location.host;
                    if ( host.indexOf( 'mindsphere.io' ) > 0 ) {
                        authUrl = 'https://' + host + '/idp/';
                    }
                    //init opCenterIPService
                    opCenterIPService.init( authUrl, 'MOMMPCognitoLogin', 'MOMMPCognitoLogout', logoutClbk, newTokenClbk, true, newLanguageClbk, newTelemetryClbk).then(function () {
                        let fSet = fedName.length > 0 ? opCenterIPService.setFederation( fedName ) : Promise.resolve();
                        //wait federation configuration end
                        fSet.then( () => {
                            //perform token verification
                            opCenterIPService.getToken().then( async function ( token ) {
                                //SSO session available
                                inLogin = false;
                                if (token !== null && token !== undefined) {
                                    const features = await opCenterIPService.getEnabledFeatures(); //.then(function () {
                                    _appCtxSvc.updateCtx('FeaturesList', features);
                                    let fList = [];
                                    if (Array.isArray(features)) {
                                        fList = features.map(x => x.Name);
                                    }
                                    _appCtxSvc.updateCtx('FeaturesListNames', fList);
                                    onAuthSolved(token, {
                                        resolve: resolve,
                                        reject: reject
                                    });
                                    setTimeout(initStats(conf.useTelemetryInternalServer), 10);
                                }
                            }, function (reason) {
                                //No session or timeout
                                opCenterIPService.login().then(async function (token) {
                                    //token received
                                    inLogin = false;
                                    if (token !== null && token !== undefined) {
                                        const features = await opCenterIPService.getEnabledFeatures(); //.then(function () {                                            console.log('***Check Feature List',features)
                                        _appCtxSvc.updateCtx('FeaturesList', features);
                                        let fList = [];
                                        if (Array.isArray(features)) {
                                            fList = features.map(x => x.Name);
                                        }
                                        _appCtxSvc.updateCtx('FeaturesListNames', fList);
                                        onAuthSolved(token, {
                                            resolve: resolve,
                                            reject: reject
                                        });
                                        setTimeout(initStats(conf.useTelemetryInternalServer), 10);
                                    }
                                }, function (reason) {
                                    reject(reason);
                                });
                            });

                        }, () => {
                            alert('Wrong umcFederation parameter');
                            reject('Wrong umcFederation parameter')
                        });
                    }, reason => reject(reason));
                }, reason => reject(reason));

                cfgNavigationPromise.then( function ( navConf ) {
                    _appCtxSvc.updateCtx('opInNavigationMenuConfig', navConf);
                    return;
                } );

            }, reason => reject(reason))
        })
    };
    exports.authenticate = function () {
        return cfgLoaded ? Promise.resolve() : Promise.reject('conf file not found');
    };
    exports.postAuthInitialization = function () {
        return cfgLoaded ? Promise.resolve() : Promise.reject('conf file not found');

    };
    exports.signOut = signOut;
    return exports;
};

function afterLogout() {
    location.replace(_appCtxSvc.getCtx('oPINAuthenticationConfig').LandingPageUrl);
};

function signOut() {
    inLogout = true;
    //perform logout
    return opCenterIPService.logout().finally(function () {
        afterLogout();
    });
};

function changeTelemetry(enableTelemetry) {
    opCenterIPService.setTelemetry(enableTelemetry.value.dbValue);
    var cfg = _appCtxSvc.getCtx('oPINAuthenticationConfig');
    var useInternalServer = cfg.useTelemetryInternalServer;
    var telDPE = _appCtxSvc.getCtx('enableTraceDPE');
    var oldVal = _appCtxSvc.getCtx('enableTrace');
    if (oldVal !== enableTelemetry.value.dbValue) {
        _appCtxSvc.updateCtx('enableTrace', enableTelemetry.value.dbValue);

        if (enableTelemetry.value.dbValue && telDPE) {
            analyticsSvc.enable(useInternalServer);
        } else {
            analyticsSvc.disable(useInternalServer);
        }
    }
    return true;
};

function changeTelemetryDPE(enableTelemetry) {
    opCenterIPService.setTelemetryDPE(enableTelemetry.value.dbValue);
    var cfg = _appCtxSvc.getCtx('oPINAuthenticationConfig');
    var useInternalServer = cfg.useTelemetryInternalServer;
    var telPEP = _appCtxSvc.getCtx('enableTrace');
    var oldVal = _appCtxSvc.getCtx('enableTraceDPE');
    if (oldVal !== enableTelemetry.value.dbValue) {
        _appCtxSvc.updateCtx('enableTraceDPE', enableTelemetry.value.dbValue);
        if (enableTelemetry.value.dbValue && telPEP) {
            analyticsSvc.enable(useInternalServer);
        } else {
            analyticsSvc.disable(useInternalServer);
        }
    }
    return true;
};
export default exportsObj = {
    getAuthenticator,
    signOut,
    changeTelemetry,
    changeTelemetryDPE
};
app.factory('oPINAuthenticatorService', () => exportsObj);